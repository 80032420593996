import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { listenToDocument, updateDocumentProperty } from '../../../utils/firestore-functions';
import { GetDB, generateGUID } from '../../../utils/database/firebase-budget-tool';
import { WhiteForm, currencyOptions, GreyLabel, PrimaryButton, PinkButtonContainer, EditIcon, Colors, WhiteTableContainer, SecondaryButton } from '../../../utils/budget-tool-specific';
import { Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, Collapse } from 'reactstrap';
import FormInputExperience from '../budget-tool/input-components/form-input-experience';
import FormInputFlight from './form-input-flight';
import { calculatePeople, calculateCostFlight, calculateAvgDuration, calculateAvgFlightPrice, calculateAvgAvgPrice, calculateAvgMinPrice, calculateAvgMaxPrice, calculateAvgMinDuration, calculateAvgMaxDuration, calculateAvgMinStops, calculateAvgMaxStops } from './flight-helper'; 
import { calculateCostAccommodation } from '../experiences/cost-summary/cost-helper';
import FlightInputRow from './flight-input-row';
import InfoModal from './infoModal';
import Papa from "papaparse";

const FlightGuideInput = (props) => {

    const budgetDB = GetDB();

    const [data, setData] = useState(
        {   title: {value: "", invalid: false, required: true},
            destination: {value: "", invalid: false, required: true},
            departureDate: {value: "", invalid: false, required: true},
            returnDate: {value: "", invalid: false, required: true},
            travelFee: {value: 0, invalid: false, required: true}, 
            currency: {value: "CAD$", invalid: false, required: true},  
            flights: {value: [], invalid: false, required: true},
    });

    const [flights, setFlights] = useState([]);

    const [savedModal, setSavedModal] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");

    const [isOpen, setIsOpen] = useState(false);

    const [copyAlert, setCopyAlert] = useState(false);

    useEffect (() =>{
        var unsubscribe = listenToDocument(budgetDB, "flightGuides", props.id, SetGuide );
        return unsubscribe;
    }, [])

    useEffect (() => {
        if (props.client){
            setIsOpen(true);
        }
    }, [props.client])

    const SetGuide = (guide) => {
        let newData = {...data};
        newData.title.value = guide.title;
        newData.destination.value =  guide.destination;
        newData.departureDate.value = guide.departureDate;
        newData.returnDate.value = guide.returnDate;
        newData.travelFee.value = guide.travelFee;
        newData.currency.value = guide.currency;
        newData.flights.value = guide.flights;
        setData(newData);
    }

    useEffect(() => {
    },[flights])

    const handleSubmit = (e, index) => {
        e.preventDefault();
        // focus on next input box
    }

    const createNewFlight = (numPassengers, departureCode) => {
        let newFlightData = {
            numPeople: numPassengers ? parseInt(numPassengers) : 0,
            originAirport: departureCode ? departureCode : "", 
            id: generateGUID(),
            priceAverage: 0,
            priceMin: 0,
            priceMax: 0,
            durationAverage: 0,
            durationMin: 0,
            durationMax: 0,
            stopsAverage: 0,
            stopsMin: 0,
            stopsMax: 0,
        }

        let newData = {...data};
        newData.flights.value[newFlightData.id] = newFlightData;
        setData(newData);
    }

    const deleteFlight = (id) => {
        let newData = {...data};
        delete newData.flights.value[id];
        setData(newData);
    }

    const saveForm = () => {
        updateDocumentProperty(budgetDB, "flightGuides", props.id, "title", data.title.value, setSavedModal(true));
        updateDocumentProperty(budgetDB, "flightGuides", props.id, "destination", data.destination.value, setSavedModal(true));
        updateDocumentProperty(budgetDB, "flightGuides", props.id, "departureDate", data.departureDate.value, setSavedModal(true));
        updateDocumentProperty(budgetDB, "flightGuides", props.id, "returnDate", data.returnDate.value, setSavedModal(true));
        updateDocumentProperty(budgetDB, "flightGuides", props.id, "currency", data.currency.value, setSavedModal(true));
        updateDocumentProperty(budgetDB, "flightGuides", props.id, "travelFee", data.travelFee.value, setSavedModal(true));
        let flights = {};
        Object.entries(data.flights.value).map((el, index) => {
            flights[el[0]] = el[1];
        });
        updateDocumentProperty(budgetDB, "flightGuides", props.id, "flights", flights, setSavedModal(true));
        
    }

    const fileInput = React.useRef(null);

    const triggerFileImport = () => {
        fileInput.current.click();
    };

    const importCSV = (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
          header: true,
          complete: (results) => {
            results.data.map((el, index) => {
                if (Object.entries(el).length > 1){
                    createNewFlight(el.numOfPassengers, el.DepartureAirportCode)
                }
            });
          },
        });
    };

    const exportCSV = () => {
        let x = [];
        Object.entries(data.flights.value).map((el, index) => {
            let d = {};
            d.originAirport = el[1].originAirport;
            d.numPeople = el[1].numPeople;
            d.priceAverage = el[1].priceAverage;
            //avg.flights.push(d);
            x.push(d);
        })
        const csv = Papa.unparse(x);
        const blob = new Blob([csv]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "data.csv";
        link.click();

        //Split out averages

        let y = [];
        let avg = {};
        avg.totalPeople = calculatePeople(data.flights.value);
        avg.totalCost = displayDollars(calculateAvgAvgPrice(data.flights.value));
        avg.costPerPerson = Math.trunc(displayDollars(calculateAvgAvgPrice(data.flights.value)/calculatePeople(data.flights.value)));
        avg.duration = displayMinutes(Math.trunc(calculateAvgDuration(data.flights.value)/calculatePeople(data.flights.value)));
        avg.minPrice = displayDollars(Math.trunc(calculateAvgMinPrice(data.flights.value)/calculatePeople(data.flights.value)));
        avg.maxPrice = displayDollars(Math.trunc(calculateAvgMaxPrice(data.flights.value)/calculatePeople(data.flights.value)));
        avg.minDuration = displayMinutes(Math.trunc(calculateAvgMinDuration(data.flights.value)/calculatePeople(data.flights.value)));
        avg.maxDuration = displayMinutes(Math.trunc(calculateAvgMaxDuration(data.flights.value)/calculatePeople(data.flights.value)));
        avg.minStops = calculateAvgMinStops(data.flights.value);
        avg.maxStops = calculateAvgMaxStops(data.flights.value);
        y.push(avg);

        const csvAverages = Papa.unparse(y);
        const blobAverages = new Blob([csvAverages]);
        const linkAverages = document.createElement("a");
        linkAverages.href = URL.createObjectURL(blobAverages);
        linkAverages.download = "averages.csv";
        linkAverages.click();
    };

    const copyLink = () => {
        let url = window.location.href.replace("edit/", "");
        navigator.clipboard.writeText(url);
        setCopyAlert(!copyAlert);
    }

    const displayDollars = (value) => {
        let text = value.toLocaleString("en-US");
        return text;
    }

    const displayMinutes = (value) => {
        let text = "";
        let minutes = value % 60;
        let hours = Math.floor(value / 60);
        if (hours !== 0){
            text += hours + " hr ";
        }
        text += minutes + " min";
        return text;
    };

    return (
        <>
        <Modal isOpen={copyAlert} toggle={() => setCopyAlert(!copyAlert)}>
                <ModalBody toggle={() => setCopyAlert(!copyAlert)}>
                    <Row style={{display: "flex", alignItems: "center"}}>
                        <Col>
                            <p style={{margin: "0px"}}>URL copied to clipboard!</p>
                        </Col>
                        <Col style={{display: "flex", justifyContent: "flex-end"}}>
                        <PrimaryButton style={{margin: "0px"}}onClick={() => setCopyAlert(!copyAlert)} >
                            Okay
                        </PrimaryButton>
                    </Col>
                    </Row>
                
                </ModalBody>
            </Modal>
        <Modal isOpen={savedModal} toggle={() => {setSavedModal(!savedModal)}}>
            <ModalHeader toggle={() => {setSavedModal(!savedModal)}}>Saved</ModalHeader>
            <ModalBody>
            Successfully saved to the experience guide database.
            </ModalBody>
            <ModalFooter>
            <PrimaryButton onClick={() => {setSavedModal(!savedModal)}}>
                Okay
            </PrimaryButton>
            </ModalFooter>
        </Modal>
        <InfoModal isOpen={infoModalOpen} setInfoModalOpen={(x) => setInfoModalOpen(x)} message={infoMessage}/>
        <Row>
            <Col>
                <WhiteForm style={{marginRight: "15px", marginLeft: "15px"}} onSubmit={e => { handleSubmit(e, 1); }}>

                { <>
                    
                    <Row>
                        <Col>
                            <FormInputExperience title="Title" 
                                        id="title" data={data} 
                                        setData={(x) => setData(x)} 
                                        type="text"
                                        client={props.client}/>
                        </Col>
                        <Col lg="3">
                            <FormInputExperience title="Destination (eg. ATH)" 
                                        id="destination" data={data} 
                                        setData={(x) => setData(x)} 
                                        type="text"
                                        client={props.client}/>
                        </Col>
                        <Col lg="2">
                            <FormInputExperience title="Client Currency" 
                                        id="currency" data={data} 
                                        setData={(x) => setData(x)} 
                                        type="select"
                                        options={currencyOptions()}
                                        client={props.client}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4">
                            <FormInputExperience title="Departure Date" 
                                        id="departureDate" data={data} 
                                        setData={(x) => setData(x, console.log(data.departureDate))} 
                                        type="date"
                                        client={props.client}/>
                        </Col>
                        <Col lg="4">
                            <FormInputExperience title="Return Date" 
                                        id="returnDate" data={data} 
                                        setData={(x) => setData(x)} 
                                        type="date"
                                        client={props.client}/>
                        </Col>
                        <Col lg="4">
                            <FormInputExperience title="Travel Fee" 
                                        id="travelFee" data={data} 
                                        currency={data.currency.value.slice(3)}
                                        setData={(x) => setData(x)} 
                                        type="number"
                                        client={props.client}/>
                        </Col>
                    </Row>
                    {props.client && <Collapse isOpen={isOpen}>
                        <WhiteForm>
                            <h4>
                                Flight Averages
                            </h4>
                        <Row>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Total People"
                                    value={calculatePeople(data.flights.value)}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Total Cost"
                                    currency={data.currency.value.slice(3)}
                                    value={displayDollars(calculateAvgAvgPrice(data.flights.value))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average Cost Per Person"
                                    currency={data.currency.value.slice(3)}
                                    value={Math.trunc(calculateAvgAvgPrice(data.flights.value)/calculatePeople(data.flights.value))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average Duration"
                                    value={displayMinutes(Math.trunc(calculateAvgDuration(data.flights.value)/calculatePeople(data.flights.value)))}/>
                                </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Min Price"
                                    currency={data.currency.value.slice(3)}
                                    value={displayDollars(Math.trunc(calculateAvgMinPrice(data.flights.value)/calculatePeople(data.flights.value)))}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Max Price"
                                    currency={data.currency.value.slice(3)}
                                    value={displayDollars(Math.trunc(calculateAvgMaxPrice(data.flights.value)/calculatePeople(data.flights.value)))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Min Duration"
                                    value={displayMinutes(Math.trunc(calculateAvgMinDuration(data.flights.value)/calculatePeople(data.flights.value)))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Max Duration"
                                    value={displayMinutes(Math.trunc(calculateAvgMaxDuration(data.flights.value)/calculatePeople(data.flights.value)))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Min Stops"
                                    value={calculateAvgMinStops(data.flights.value)}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Max Stops"
                                    value={calculateAvgMaxStops(data.flights.value)}/>
                            </Col>
                        </Row>
                        </WhiteForm>
                    </Collapse>}
                    <GreyLabel>
                        Flights
                    </GreyLabel>
                    <Row>
                        <DarkDiv>
                            {data.flights.value && Object.values(data.flights.value).map((flight, index) => {
                                
                                return (
                                    <FlightInputRow data={data} 
                                                    flight={flight}
                                                    setData={setData} 
                                                    key={flight.id} 
                                                    client={props.client}
                                                    i={flight.id} 
                                                    id={flight.id}
                                                    deleteFlight={(x) => deleteFlight(x)}
                                                    flights={flights}
                                                    setFlights={(x)=> setFlights(x)}
                                                    setInfoModalOpen={(x) => setInfoModalOpen(x)}
                                                    setInfoMessage={(x) => setInfoMessage(x)}/>
                                )
                            })}
                            {!props.client && <>
                            <Row>
                                <Col style={{padding: "0px"}}>
                                    <StyledButton onClick={() => createNewFlight()}>
                                        Add Flight
                                    </StyledButton>
                                </Col>
                            </Row>
                            </>}
                        </DarkDiv>
                    </Row>
                </>}
                {!props.client && <> 
                    <PrimaryButton style={{margin: "15px 15px 15px 0px"}} onClick={() => saveForm()}>
                        Save Form
                    </PrimaryButton>
                </>}
                    
                {!props.client && <PrimaryButton style={{margin: "15px 15px 15px 0px"}} onClick={() => setIsOpen(!isOpen)}>
                        {!isOpen && <p style={{marginBottom: "0px"}}> Show Values </p>}
                        {isOpen && <p style={{marginBottom: "0px"}}> Hide Values </p>}
                    </PrimaryButton>}
                {!props.client && <>
                    <StyledButton style={{margin: "15px 15px 15px 0px" }} onClick={() => copyLink()}>
                        Copy Client URL
                    </StyledButton>
                </>}
                {!props.client && <> 
                    <input ref={fileInput} style={{ display:"none"}} type="file" onChange={(event) => {importCSV(event)}} />
                    <PrimaryButton style={{margin: "15px 15px 15px 0px"}} onClick={() => triggerFileImport()}>
                        Import CSV
                    </PrimaryButton>
                </>}
                {!props.client && <> 
                    <PrimaryButton style={{margin: "15px 15px 15px 0px"}} onClick={() => exportCSV()}>
                        Export CSV
                    </PrimaryButton>
                </>}

                    {!props.client && <Collapse isOpen={isOpen}>
                        <WhiteForm>
                            <h4>
                                Flight Values
                            </h4>
                        <Row>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Total People"
                                    value={calculatePeople(data.flights.value)}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Total Cost"
                                    currency={data.currency.value.slice(3)}
                                    value={displayDollars(calculateAvgAvgPrice(data.flights.value))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average Cost Per Person"
                                    currency={data.currency.value.slice(3)}
                                    value={Math.trunc(calculateAvgAvgPrice(data.flights.value)/calculatePeople(data.flights.value))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average Duration"
                                    value={displayMinutes(Math.trunc(calculateAvgDuration(data.flights.value)/calculatePeople(data.flights.value)))}/>
                                </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Min Price"
                                    currency={data.currency.value.slice(3)}
                                    value={displayDollars(Math.trunc(calculateAvgMinPrice(data.flights.value)/calculatePeople(data.flights.value)))}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Max Price"
                                    currency={data.currency.value.slice(3)}
                                    value={displayDollars(Math.trunc(calculateAvgMaxPrice(data.flights.value)/calculatePeople(data.flights.value)))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Min Duration"
                                    value={displayMinutes(Math.trunc(calculateAvgMinDuration(data.flights.value)/calculatePeople(data.flights.value)))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Max Duration"
                                    value={displayMinutes(Math.trunc(calculateAvgMaxDuration(data.flights.value)/calculatePeople(data.flights.value)))}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Min Stops"
                                    value={(Math.trunc((calculateAvgMinStops(data.flights.value)/calculatePeople(data.flights.value))*100))/100}/>
                            </Col>
                            <Col>
                                <FormInputFlight
                                    unchanged
                                    title="Average of Max Stops"
                                    value={(Math.trunc((calculateAvgMaxStops(data.flights.value)/calculatePeople(data.flights.value))*100))/100}/>
                            </Col>
                        </Row>
                        </WhiteForm>
                    </Collapse>}
                    <br/>
                </WhiteForm>
            </Col>
        </Row>

        </>

    )
}

export default FlightGuideInput;

const StyledButton = styled(Button)`
    background-color: ${Colors.primaryAccent};
    border: 0px;
    border: none;
    margin: 0px 15px;
    &:hover {
        background-color: ${Colors.primaryDark};
    }
    display: inline;
    color: ${Colors. primaryDark};
`

const UnhighlightedInput = styled(Input)`
    
`

const FlightRow = styled(Row)`
`

const DarkDiv = styled.div`
    width: 100%;
    padding: 15px;
    border-radius: 0px;
    border: 1px solid darkgrey;
    background-color: white;
    margin: 1px 15px;
`